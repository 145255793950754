

function formatDollars(amount){
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }).format(amount);
}

function appendQueryParamsToRelativeUrl(relativeUrl, params) {
  let query = [];
  for (let key in params) {
      if (params.hasOwnProperty(key)) {
          query.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
  }

  if (relativeUrl.includes('?')) {
      return relativeUrl + '&' + query.join('&');
  } else {
      return relativeUrl + '?' + query.join('&');
  }
}

export {formatDollars, appendQueryParamsToRelativeUrl};