import mockDataOutback from "./outbackData";
import productSalesOverTimeMock from "./product_sales_over_time_mock";
import productSalesOverTimeDetailMock from "./product_sales_over_time_detail_mock";
import productSalesOverTimeDetailMock2 from "./product_sales_over_time_detail_mock2";
import { REPORT_META } from "../constants";

const mockData = {
    "/api/auth/token/login/": {
        "authToken" : "Leo"
    },
    "/api" : {
        "business_name" : "TestTest",
        "username" : "Tester",
        "reports" : [
            {
                "name" : "Sales By Product",
                "app_name" : "report_sales_by_product",
                "icon" : "TableChartIcon",
                "description" : "Great Report"
            },
            {
                "name" : "Product Sales Over Time",
                "app_name" : "report_product_sales_over_time",
                "icon" : "CategoryIcon",
                "description" : "Greater Report"
            }
        ]
    },
    [REPORT_META.report_sales_by_product.main_api_endpoint] : mockDataOutback,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint] : productSalesOverTimeMock,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint + "sku/SKU-4188TO"] : productSalesOverTimeDetailMock,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint + "sku/SKU-6903rc"] : productSalesOverTimeDetailMock2,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint + "sku/SKU-4839eS"] : productSalesOverTimeDetailMock,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint + "sku/SKU-2535iG"] : productSalesOverTimeDetailMock,
    [REPORT_META.report_product_sales_over_time.main_api_endpoint + "sku/SKU-0528ah"] : productSalesOverTimeDetailMock

}


export default mockData;