import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import YBWidget from "../../components/YBWidget";
import apiService from "../../services/apiService";
import {WIDGET_META} from "../../constants";
import { useState } from "react";

const Dashboard = () => {
  const { businessData } = useAuth();
  const [ salesDeltaData, setSalesDeltaData ] = useState([]);
  const { authToken } = useAuth();

  useEffect(() => {
    document.title = "Yotabase: Dashboard";
  }, []);


  async function getAverageSalesWidgetData(){
    setSalesDeltaData(await apiService.get(WIDGET_META.report_product_sales_over_time.average_sales_delta.url, authToken));
    
  }

  useEffect(() => {
    getAverageSalesWidgetData();
  }, []);

  console.log(salesDeltaData)


  return (
    <div>
      <Box m="16px">
        <Header
          title="Yotabase Dashboard"
          subtitle={`Welcome to ${businessData?.business_name}'s dashboard, ${businessData?.username}!`}
        />{" "}
      </Box>

      {/* Widgets */}
      {salesDeltaData.length && <YBWidget objArray={salesDeltaData}/>}
    </div>
  );
};

export default Dashboard;
