import React, { useState, useEffect } from "react";
import { Box, useTheme, Button, Grid, InputLabel } from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import apiService from "../../services/apiService";
import { FormControl } from "@mui/material";
import Stack from "@mui/material/Stack";
import { REPORT_META } from "../../constants";
import { InputAdornment, TextField, createStyles } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ProductSalesOverTimeCustomTable from "../../components/productsSalesOverTimeTable";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { BarChart } from "@mui/x-charts/BarChart";

import dayjs from "dayjs";
import { useMediaQuery } from "@mui/material";
import { useRef } from "react";
import YBDatePicker  from "../../components/YBDatePicker";
import {appendQueryParamsToRelativeUrl} from "../global/utils";

// ...

const ProductSalesOverTimePage = () => {
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartWidth = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const { authToken, logout } = useAuth();

  const [skuBarChartData, setskuBarChartData] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState("");
  // const [displayBarChartBy, setDisplayBarChartBy] = useState("month");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [skuValue, setSkuValue] = useState("");

  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());

  const [chartSliderValues, setChartSliderValues] = useState([0, 100]);

  async function fetchData() {
    let url = REPORT_META.report_product_sales_over_time.main_api_endpoint;

    if (skuValue) {
      url = appendQueryParamsToRelativeUrl(url, {
        product__sku: skuValue,
      });
    }

    if (startDate && endDate) {
      url = appendQueryParamsToRelativeUrl(url, {
        date_placed_range_after: formatDate(startDate),
        date_placed_range_before: formatDate(endDate),
      });
    }

    console.log("URL with filters:", url);
  
    try {
      setLoading(true);
      const response = await apiService.get(url, authToken);
      setTableData(response.results);
      setRowCount(response.count);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  }
  


  useEffect(() => {
    if (tableData.length === 0) return;
    updateSKUChart(tableData[0].sku);
    setSelectedSKU(tableData[0].sku);
  }, [tableData]);


  useEffect(() => {
    fetchData();
  }, [startDate, endDate])


  const searchTimeout = useRef(null);
  useEffect(() => {
    // Clear the previous timeout
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    // Set a new timeout
    searchTimeout.current = setTimeout(() => {
      fetchData();
    }, 1000); // 1000 milliseconds = 1 second
  }, [skuValue]);


  function formatDate(dateStr, reverse=false) {
    const date = new Date(dateStr);
    let result = date.toISOString().split('T')[0];
    if(reverse){
      result = result.split('-').reverse().join('-');
    }
    return result;
  }

  async function updateSKUChart(sku) {

    let url = `${REPORT_META.report_product_sales_over_time.main_api_endpoint}sku/${sku}`;

    if (startDate && endDate) {
      url = appendQueryParamsToRelativeUrl(url, {
        date_placed_range_after: formatDate(startDate),
        date_placed_range_before: formatDate(endDate),
      });
    }


    const response = await apiService.get(url, authToken);
    setskuBarChartData(response.results);
  }


  function onSKUClicked(sku){
    updateSKUChart(sku);
    setSelectedSKU(sku);
  }

  function search(sku){
    setShowClearIcon(sku === "" ? "none" : "flex");
    setSkuValue(sku);
  }

  function handleSearchSKUChange(event){
    search(event.target.value);
  };


  function handleSearchSKUCrossClick(){
    search("")
  };




  // let filteredData = skuBarChartData?.filter((record) => {
  //   const recordDate = dayjs(record.date);

  //   if (startDate && endDate) {
  //     return recordDate.isAfter(startDate) && recordDate.isBefore(endDate);
  //   } else {
  //     const thirtyDaysAgo = dayjs().subtract(30, "day");
  //     return recordDate.isAfter(thirtyDaysAgo) && recordDate.isBefore(dayjs());
  //   }
  // });


  // let datesData, soldData;


  // if (filteredData) {
  //   filteredData = filteredData?.map((item) => {
  //     return {
  //       ...item,
  //       date: dayjs(item.date).format("YYYY-MM-DD"),
  //     };
  //   });
  //   soldData = filteredData?.map(({ sold }) => sold);
  //   datesData = filteredData?.map(({ date }) => date);
  // }

  return (
    <Box m="20px">
      <Header
        title="Product Sales Over Time"
        subtitle="This report will tell you your product sales for any given time period. Clicking into a product will show a graph charting that products sales by month in the given range."
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.orangeAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[400]} !important`,
          },
        }}
      >
        {error && <p>Error: {error}</p>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        ></form>
        <h1>Products</h1>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3} height={360}>
            <FormControl>
              <TextField
                style={{ marginTop: "8px" }}
                id="sku-input"
                label="Search by SKU"
                size="medium"
                variant="outlined"
                value={skuValue}
                onChange={handleSearchSKUChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={handleSearchSKUCrossClick}
                    >
                      <ClearIcon style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <ProductSalesOverTimeCustomTable data={tableData} loading={loading} onSKUClick={onSKUClicked}/>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: md ? 90 : 0 }}
            md={9}
            ref={chartWidth}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <Grid  display={!md ? "flex" : 'block'} gap={2}>
                <Grid item xs={12} md={6} marginBottom={!md ? 0 : 2}>
                  <YBDatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <YBDatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </Grid>
                </Grid>
              </DemoContainer>
            </LocalizationProvider>
            <Box><h1>SKU: {selectedSKU}</h1></Box>
            {skuBarChartData && skuBarChartData?.length > 0 ? (
              <BarChart
                width={chartWidth.current.innerWidth}
                height={300}
                series={[{ data: skuBarChartData.map((x) => x.sold), id: "soldId" }]}
                xAxis={[{ data: skuBarChartData.map((x) => formatDate(x.date, true)), scaleType: "band" }]}
              />
            ) : (
              <h4>No record found</h4>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductSalesOverTimePage;
