const productSalesOverTimeDetailMock2 = {
    "count": 3,
    "next": null,
    "previous": null,
    "results": [
        
        {
            "date": "2022-01-01T00:00:00-06:00",
            "sold": 83
        },
        {
            "date": "2024-01-09T00:00:00-06:00",
            "sold": 32
        },
        {
            "date": "2024-02-06T00:00:00-06:00",
            "sold": 14
        }
    ]
}

export default productSalesOverTimeDetailMock2;