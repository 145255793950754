import TableChartIcon from "@mui/icons-material/TableChart";
import CategoryIcon from '@mui/icons-material/Category';


const WIDGET_META = {
    report_product_sales_over_time : {
        average_sales_delta : {
            url: "/api/v1/reports/report-product-sales-over-time/widgets/product-sales-delta/"
        }
    }
}


const REPORT_META = {
    report_sales_by_product : {
        main_api_endpoint : "/api/v1/reports/report-sales-by-product/",
        icon: <TableChartIcon />,
        url: "/report/sales-by-product"
    },
    report_product_sales_over_time : {
        main_api_endpoint : "/api/v1/reports/report-product-sales-over-time/",
        icon: <CategoryIcon />,
        url: "/report/product-sales-over-time"
    }
}


export { REPORT_META, WIDGET_META }