const productSalesOverTimeMock = {
    "count": 50,
    "next": null,
    "previous": null,
    "results": [
        {
            "sku": "SKU-4188TO",
            "sold": 29,
            "rank": 1
        },
        {
            "sku": "SKU-6903rc",
            "sold": 19,
            "rank": 2
        },
        {
            "sku": "SKU-4839eS",
            "sold": 14,
            "rank": 3
        },
        {
            "sku": "SKU-2535iG",
            "sold": 11,
            "rank": 4
        },
        {
            "sku": "SKU-0528ah",
            "sold": 10,
            "rank": 5
        },
        {
            "sku": "SKU-4641hN",
            "sold": 8,
            "rank": 6
        },
        {
            "sku": "SKU-9390pZ",
            "sold": 8,
            "rank": 7
        },
        {
            "sku": "SKU-7502at",
            "sold": 7,
            "rank": 8
        },
        {
            "sku": "SKU-6144hM",
            "sold": 6,
            "rank": 9
        },
        {
            "sku": "SKU-8714bt",
            "sold": 5,
            "rank": 10
        },
        {
            "sku": "SKU-2624CJ",
            "sold": 4,
            "rank": 11
        },
        {
            "sku": "SKU-4188TO",
            "sold": 29,
            "rank": 1
        },
        {
            "sku": "SKU-6903rc",
            "sold": 19,
            "rank": 2
        },
        {
            "sku": "SKU-4839eS",
            "sold": 14,
            "rank": 3
        },
        {
            "sku": "SKU-2535iG",
            "sold": 11,
            "rank": 4
        },
        {
            "sku": "SKU-0528ah",
            "sold": 10,
            "rank": 5
        },
        {
            "sku": "SKU-4641hN",
            "sold": 8,
            "rank": 6
        },
        {
            "sku": "SKU-9390pZ",
            "sold": 8,
            "rank": 7
        },
        {
            "sku": "SKU-7502at",
            "sold": 7,
            "rank": 8
        },
        {
            "sku": "SKU-6144hM",
            "sold": 6,
            "rank": 9
        },
        {
            "sku": "SKU-8714bt",
            "sold": 5,
            "rank": 10
        },
        {
            "sku": "SKU-2624CJ",
            "sold": 4,
            "rank": 11
        },
        {
            "sku": "SKU-4188TO",
            "sold": 29,
            "rank": 1
        },
        {
            "sku": "SKU-6903rc",
            "sold": 19,
            "rank": 2
        },
        {
            "sku": "SKU-4839eS",
            "sold": 14,
            "rank": 3
        },
        {
            "sku": "SKU-2535iG",
            "sold": 11,
            "rank": 4
        },
        {
            "sku": "SKU-0528ah",
            "sold": 10,
            "rank": 5
        },
        {
            "sku": "SKU-4641hN",
            "sold": 8,
            "rank": 6
        },
        {
            "sku": "SKU-9390pZ",
            "sold": 8,
            "rank": 7
        },
        {
            "sku": "SKU-7502at",
            "sold": 7,
            "rank": 8
        },
        {
            "sku": "SKU-6144hM",
            "sold": 6,
            "rank": 9
        },
        {
            "sku": "SKU-8714bt",
            "sold": 5,
            "rank": 10
        },
        {
            "sku": "SKU-2624CJ",
            "sold": 4,
            "rank": 11
        },
        {
            "sku": "SKU-4188TO",
            "sold": 29,
            "rank": 1
        },
        {
            "sku": "SKU-6903rc",
            "sold": 19,
            "rank": 2
        },
        {
            "sku": "SKU-4839eS",
            "sold": 14,
            "rank": 3
        },
        {
            "sku": "SKU-2535iG",
            "sold": 11,
            "rank": 4
        },
        {
            "sku": "SKU-0528ah",
            "sold": 10,
            "rank": 5
        },
        {
            "sku": "SKU-4641hN",
            "sold": 8,
            "rank": 6
        },
        {
            "sku": "SKU-9390pZ",
            "sold": 8,
            "rank": 7
        },
        {
            "sku": "SKU-7502at",
            "sold": 7,
            "rank": 8
        },
        {
            "sku": "SKU-6144hM",
            "sold": 6,
            "rank": 9
        },
        {
            "sku": "SKU-8714bt",
            "sold": 5,
            "rank": 10
        },
        {
            "sku": "SKU-2624CJ",
            "sold": 4,
            "rank": 11
        },
        {
            "sku": "SKU-4641hN",
            "sold": 8,
            "rank": 6
        },
        {
            "sku": "SKU-9390pZ",
            "sold": 8,
            "rank": 7
        },
        {
            "sku": "SKU-7502at",
            "sold": 7,
            "rank": 8
        },
        {
            "sku": "SKU-6144hM",
            "sold": 6,
            "rank": 9
        },
        {
            "sku": "SKU-8714bt",
            "sold": 5,
            "rank": 10
        },
        {
            "sku": "SKU-2624CJ",
            "sold": 4,
            "rank": 11
        }
    ]
}

export default productSalesOverTimeMock;