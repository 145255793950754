import { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import SalesByProductPage from "./scenes/sales-by-product";
import ProductSalesOverTimePage from "./scenes/product-sales-over-time";
import LoginForm from "./scenes/dashboard/LoginForm";
import { useAuth } from "./contexts/AuthContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { REPORT_META } from "./constants";

function App() {

  const [theme, colorMode] = useMode();
  const scrollHeight = useRef()
  // const [scrollHeight, setScrollHeight] = useState(document.getElementById('screenHeight')?.scrollHeight)
  const { authToken } = useAuth();
  const [isSidebar, setIsSidebar] = useState(true);

  if (!authToken) {
    return <LoginForm />;
  }
  
  return (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className="app"  ref={scrollHeight}>
    <Sidebar isSidebar={isSidebar} height={+scrollHeight.current?.scrollHeight + +window.outerHeight} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path={REPORT_META.report_sales_by_product.url} element={<SalesByProductPage />} />
              <Route path={REPORT_META.report_product_sales_over_time.url} element={<ProductSalesOverTimePage />} />
            </Routes>
        </main>      
    </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
