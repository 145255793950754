export const mockDataOutback = {
    results: [
        {
            "u_id" : 1,
            "sku": "SE-17228",
            "name": "Superior Coil Drop Out Cone To Suit Nissan Patrol GQ/GU (Bolt In) (Pair)",
            "inventory_id": 523727,
            "brand": "Superior Engineering",
            "date_invoiced": "2023-08-22",
            "revenue": "95.45",
            "order_count": 1,
            "total_sold": 1,
            "total_refunded": 0,
            "value_refunded": "0.00",
            "cogs": "95.45",
            "gross_profit": "0.00",
            "margin_percent": "0.00"
        },
        {
            "u_id" : 2,
    
            "sku": "73X40",
            "name": "Bushranger Diggar Shovel",
            "inventory_id": 58305,
            "brand": "Bushranger 4x4 Gear",
            "date_invoiced": "2021-11-23",
            "revenue": "160.00",
            "order_count": 2,
            "total_sold": 2,
            "total_refunded": 0,
            "value_refunded": "0.00",
            "cogs": "96.00",
            "gross_profit": "64.00",
            "margin_percent": "40.00"
        },
        {
            "u_id" : 3,
    
            "sku": "MTX02SB",
            "name": "MAXTRAX MKII Recovery Tracks [Colour: Sky Blue] ",
            "inventory_id": 14274,
            "brand": "Maxtrax",
            "date_invoiced": "2018-11-18",
            "revenue": "231.81",
            "order_count": 1,
            "total_sold": 1,
            "total_refunded": 0,
            "value_refunded": "0.00",
            "cogs": "0.00",
            "gross_profit": "231.81",
            "margin_percent": "100.00"
        },
        {
            "u_id" : 4,
    
            "sku": "TTMTLC70CRM",
            "name": "Extendable Towing Mirrors For Toyota Landcruiser 75, 76, 78 & 79 Series - Chrome/Manual",
            "inventory_id": 142303,
            "brand": "Tuff Terrain",
            "date_invoiced": "2020-03-15",
            "revenue": "445.45",
            "order_count": 1,
            "total_sold": 1,
            "total_refunded": 0,
            "value_refunded": "0.00",
            "cogs": "0.00",
            "gross_profit": "445.45",
            "margin_percent": "100.00"
        },
        {
            "u_id" : 5,
    
            "sku": "ST120A",
            "name": "Tuff Terrain Snorkel Kit To Suit Toyota Prado 120 Series 2002 - 2009",
            "inventory_id": 1028,
            "brand": "Tuff Terrain",
            "date_invoiced": "2019-06-12",
            "revenue": "80.90",
            "order_count": 1,
            "total_sold": 1,
            "total_refunded": 0,
            "value_refunded": "0.00",
            "cogs": "0.00",
            "gross_profit": "80.90",
            "margin_percent": "100.00"
        }
    ],
    count: 5555,
    total_revenue: 923452342342349.324,
    total_refunded: 234.12,
    total_gross_profit: 111.11
}
    
export default mockDataOutback;