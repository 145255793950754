import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

// const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData = [2400, 1398, -9800, 3908, 4800, -3800, 4300];



function YBWidget({ objArray }){

    const xLabels = objArray.map((obj) => obj.sku);

    console.log(xLabels);
    console.log(objArray)


    return (
        <div>
            <h1 style={{marginLeft: "35px", marginBottom: "-35px"}}>SKU sales change on last month</h1>
            <BarChart
                width={500}
                height={300}
                series={[
                {
                    data: objArray.map((obj) => obj.absolute_difference),
                    // label: 'pv',
                },
                ]}
                xAxis={[
                {
                    data: xLabels,
                    scaleType: 'band'
                },
                ]}
                // yAxis={[{ max: 500 }]}
            />
        </div>
    );
}



export default YBWidget;