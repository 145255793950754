const productSalesOverTimeDetailMock = {
    "count": 3,
    "next": null,
    "previous": null,
    "results": [
        
        {
            "date": "2024-01-01T00:00:00-06:00",
            "sold": 8
        },
        {
            "date": "2024-01-04T00:00:00-06:00",
            "sold": 3
        },
        {
            "date": "2024-01-06T00:00:00-06:00",
            "sold": 1
        }
    ]
}

export default productSalesOverTimeDetailMock;