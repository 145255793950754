import axios from 'axios';

const isDevelopment = false;//process.env.NODE_ENV === 'development';

let mockData;
if(isDevelopment) {
    mockData = require('../data/newMockData').default;
}

function getBaseUrl(url) {
    // Create a URL object (assuming you have a full URL or you can prepend a dummy origin)
    // If 'url' is a relative URL, you can prepend it with a dummy origin like 'http://localhost'
    const urlObj = new URL(url, 'http://localhost');
  
    // Return the pathname, which is the base part of the URL
    return urlObj.pathname;
  }

const apiService = {
    get: async (url, authToken) => {
        if (isDevelopment) {
            return mockData[getBaseUrl(url)]; // Return mock data in development
        }
        try {
            const headers = {
                "Content-Type": "application/json"
            };
            if (authToken) {
                headers.Authorization = `Token ${authToken}`;
            }
            const response = await axios.get(url, { headers });
            return response.data;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    },
    post: async (url, data, authToken) => {
        if (isDevelopment) {
            return mockData[getBaseUrl(url)]; // Return mock data in development
        }
        try {
            const headers = {
                "Content-Type": "application/json"
            };
            if (authToken) {
                headers.Authorization = `Token ${authToken}`;
            }
            const response = await axios.post(url, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    },
};

export default apiService;
